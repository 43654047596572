import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import * as S from './BumpOfferScrollV1.styles';
import 'react-responsive-carousel/lib/styles/carousel.css';
import { formatPrice } from '../../utils/formatPrice';
import Checkmark from '../../../static/images/checkmark.inline.svg';
import BumpCarousel from '../BumpCarousel/BumpCarousel';

const BumpOfferScroll = props => {
  const {
    showSaleBadge,
    showDiscountPercentage,
    saleBadgeText,
    mobileCards,
    width,
    mobileWidth,
    showTitle,
    otpTitle,
    subTitle
  } = props;

  const context = useContext(FunnelContext);

  const {
    currency,
    onetime,
    subscription,
    currentCategory,
    pageDesign,
    bumpOfferArray,
    setBumpOfferArray
  } = context;

  const priceSettings = { currencySymbol: currency?.symbol || '$' };

  const bumpoffers =
    currentCategory === `onetime`
      ? onetime?.bumpoffers
      : subscription?.bumpoffers;

  const handleButton = index => {
    const isSelected = bumpOfferArray?.includes(index);
    if (isSelected) {
      setBumpOfferArray(bumpOfferArray?.filter(item => item !== index));
    } else {
      setBumpOfferArray([...bumpOfferArray, index]);
    }
  };

  return (
    <S.BumpOfferWrapper>
      <BumpCarousel
        slidesVisible={2}
        showControls
        width={width}
        mobileWidth={mobileWidth}
        shadow={'0px 4px 20px rgba(0, 0, 0, 0.1)'}
        showTitle={showTitle}
        otpTitle={otpTitle}
        subTitle={subTitle}
      >
        {bumpoffers.map((item, i) => (
          <S.BumpOffer key={item?.checkoutData?.variant_id}>
            <S.BumpOfferItem
              key={item?.checkoutData?.variant_id}
              onClick={() => handleButton(i)}
              mobileCards={mobileCards}
              selected={bumpOfferArray?.includes(i)}
            >
              <S.BumpOfferImage
                mobileCards={mobileCards}
                pageDesign={pageDesign}
              >
                {showSaleBadge && (
                  <S.SaleBadgeAbsolute mobileCards={mobileCards}>
                    {saleBadgeText}
                  </S.SaleBadgeAbsolute>
                )}
                <S.Checkbox
                  selected={bumpOfferArray?.includes(i)}
                  mobileCards={mobileCards}
                >
                  {bumpOfferArray?.includes(i) ? <Checkmark /> : null}
                </S.Checkbox>
                <img
                  alt="bump offer product"
                  loading="lazy"
                  src={item?.checkoutData.image}
                  width={56}
                  height={56}
                />
              </S.BumpOfferImage>
              <S.Description>
                <S.DisplayTitle
                  selected={bumpOfferArray?.includes(i)}
                  mobileCards={mobileCards}
                >
                  {item?.display_title}
                </S.DisplayTitle>
                {showSaleBadge && (
                  <S.SaleBadgeMobile
                    pageDesign={pageDesign}
                    mobileCards={mobileCards}
                  >
                    {saleBadgeText}
                  </S.SaleBadgeMobile>
                )}
                {showDiscountPercentage && (
                  <S.DiscountPercentage mobileCards={mobileCards}>
                    {item?.discount_percentage}% Off
                  </S.DiscountPercentage>
                )}
                <S.Price>
                  <S.DiscountedPrice>
                    {formatPrice(
                      item?.checkoutData?.discounted_price,
                      priceSettings
                    )}
                  </S.DiscountedPrice>
                  &nbsp;
                  <S.FullPrice>
                    {formatPrice(item?.checkoutData?.price, priceSettings)}
                  </S.FullPrice>
                </S.Price>
              </S.Description>
            </S.BumpOfferItem>
          </S.BumpOffer>
        ))}
      </BumpCarousel>
    </S.BumpOfferWrapper>
  );
};

export default BumpOfferScroll;
